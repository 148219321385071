<template>
<section class="w-full">
    <div class="container mx-auto px-8">
        <div class="lg:w-8/12 mx-auto py-12">
            <h3 class="text-center text-2xl lg:text-4xl font-body py-5 font-semibold" v-html="$t('goal.title')"></h3>
            <ol class="list-decimal pl-5 leading-loose" v-html="$t('goal.text')"></ol>
        </div>
        <div class="lg:w-8/12 mx-auto py-12">
            <h3 class="text-center text-2xl lg:text-4xl font-body py-5 font-semibold" v-html="$t('realization.title')"></h3>
            <ol class="list-decimal pl-5 leading-loose" v-html="$t('realization.text')"></ol>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: "Goal",
};
</script>
